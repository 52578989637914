import * as yup from 'yup';

export const getSchema = (isUpdate: boolean): any => {
  return yup.object().shape({
    cod_loja: yup
      .object()
      .shape({
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .required(),
    ...(isUpdate === false && {
      cod_cc_receber: yup
        .object()
        .shape({
          value: yup.number().required(),
          label: yup.string().required(),
        })
        .required(),
      cod_pessoa: yup
        .object()
        .shape({
          value: yup.number().required(),
          label: yup.string().required(),
        })
        .required(),
      cod_categoria_receber: yup
        .object()
        .shape({
          value: yup.number().required(),
          label: yup.string().required(),
        })
        .required(),
      cod_finalizadora_receber: yup
        .object()
        .shape({
          value: yup.number().required(),
          label: yup.string().required(),
        })
        .required(),
    }),
    cod_cc_receber: yup
      .object()
      .shape({
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .when([], {
        is: () => isUpdate,
        then: (schema) =>
          schema.test(
            'cod-cc-empty-check',
            'cod_cc is required when it is an empty string on update',
            (value) => value?.label !== '',
          ),
      }),
    cod_categoria_receber: yup
      .object()
      .shape({
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .when([], {
        is: () => isUpdate,
        then: (schema) =>
          schema.test(
            'cod-categoria-empty-check',
            'cod_categoria_receber is required when it is an empty string on update',
            (value) => value?.label !== '',
          ),
      }),
    dta_emissao: yup.string().required(),
    dta_entrada: yup.string().required(),
    val_parcela: yup.string().required(),
    num_docto: yup.string().required(),
    dta_vencimento: yup.string().required(),
    dta_quitada: yup.string().when('flg_quitado', {
      is: true,
      then: yup.string().required(),
      otherwise: yup.string().notRequired(),
    }),
    flg_quitado: yup.boolean().notRequired(),
    flg_leitura_vda: yup.boolean().notRequired(),
  });
};
